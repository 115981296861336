import React, { FC, useCallback } from 'react';
import UIkitPicture, { jpegBackupSource, ImageFormat } from '@westwing/ui-kit/Picture';
import { getMediaStringValue, iphoneXMediaQuery } from '@westwing/ui-kit/breakPoints';
import { contentfulWidthFormatter } from '../../Generic/ContentInfusionsSlider/imageFormatter';
import { useLogger } from 'AppShell/logger/LoggerProvider';

type LooksImageProps = {
    className?: string;
    src: string;
    seoSrc: string;
    isPortrait: boolean;
    alt?: string;
    onImageLoad: (src: string) => void;
    loading?: 'eager' | 'lazy';
};

export enum ImageWidths {
    LLP_DESKTOP_TABLET_LOW_DENSITY_PORTRAIT = 600,
    LLP_DESKTOP_TABLET_LOW_DENSITY_LANDSCAPE = 1200,
    LLP_DESKTOP_TABLET_HIGH_DENSITY_PORTRAIT = 1200,
    LLP_DESKTOP_TABLET_HIGH_DENSITY_LANDSCAPE = 2400,
    LLP_IPHONE_X_PORTRAIT = 375,
    LLP_IPHONE_X_LANDSCAPE = 750,
    LLP_MOBILE_LOW_DENSITY_PORTRAIT = 460,
    LLP_MOBILE_LOW_DENSITY_LANDSCAPE = 920,
    LLP_MOBILE_HIGH_DENSITY_PORTRAIT = 920,
    LLP_MOBILE_HIGH_DENSITY_LANDSCAPE = 1840,
}

export const LDPImage: FC<LooksImageProps> = ({
    alt = '',
    className,
    isPortrait,
    src,
    seoSrc,
    onImageLoad,
    loading = 'lazy',
}) => {
    const logger = useLogger();

    const containerRef = useCallback(
        node => {
            const imgElement = node?.querySelector('img');
            if (imgElement?.complete) {
                onImageLoad(imgElement.src);
            } else if (imgElement) {
                imgElement.onload = () => {
                    onImageLoad(imgElement.src);
                };
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (!src) {
        return null;
    }

    const logImageErrorWarning = () => {
        logger.warn(`[Contentful] LDP Image with src - "${src}" failed to load`);
    };

    const { webpSrc: iphoneXSrcWebp, jpegSrc: iphoneXSrcJpeg } = contentfulWidthFormatter(
        src,
        isPortrait ? ImageWidths.LLP_IPHONE_X_PORTRAIT : ImageWidths.LLP_IPHONE_X_LANDSCAPE
    );

    const { webpSrc: mobileSrcWebp, jpegSrc: mobileSrcJpeg } = contentfulWidthFormatter(
        src,
        isPortrait ? ImageWidths.LLP_MOBILE_LOW_DENSITY_PORTRAIT : ImageWidths.LLP_MOBILE_LOW_DENSITY_LANDSCAPE
    );

    const { webpSrc: mobileSrcWebp2xDpi, jpegSrc: mobileSrcJpeg2xDpi } = contentfulWidthFormatter(
        src,
        isPortrait ? ImageWidths.LLP_MOBILE_HIGH_DENSITY_PORTRAIT : ImageWidths.LLP_MOBILE_HIGH_DENSITY_LANDSCAPE
    );

    const { webpSrc: desktopTabletWebpSrc, jpegSrc: desktopTabletJpegSrc } = contentfulWidthFormatter(
        src,
        isPortrait
            ? ImageWidths.LLP_DESKTOP_TABLET_LOW_DENSITY_PORTRAIT
            : ImageWidths.LLP_DESKTOP_TABLET_LOW_DENSITY_LANDSCAPE
    );

    const { webpSrc: desktopTabletWebp2xDpiSrc, jpegSrc: desktopTabletJpeg2xDpiSrc } = contentfulWidthFormatter(
        src,
        isPortrait
            ? ImageWidths.LLP_DESKTOP_TABLET_HIGH_DENSITY_PORTRAIT
            : ImageWidths.LLP_DESKTOP_TABLET_HIGH_DENSITY_LANDSCAPE
    );

    const tabletSource2xDpi = jpegBackupSource(
        {
            media: getMediaStringValue('breakpointTablet', true),
            url: mobileSrcWebp2xDpi,
            value: 2,
            format: ImageFormat.WEBP,
        },
        mobileSrcJpeg2xDpi
    );

    const sources = [
        ...jpegBackupSource(
            {
                media: iphoneXMediaQuery,
                url: iphoneXSrcWebp,
                value: 1,
                format: ImageFormat.WEBP,
            },
            iphoneXSrcJpeg
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet', true),
                url: mobileSrcWebp,
                value: 1,
                format: ImageFormat.WEBP,
            },
            mobileSrcJpeg
        ),

        ...tabletSource2xDpi,

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: desktopTabletWebpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            desktopTabletJpegSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: desktopTabletWebp2xDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            desktopTabletJpeg2xDpiSrc
        ),
    ];

    return (
        <div ref={containerRef}>
            <UIkitPicture
                sources={sources}
                loading={loading}
                fallback={{
                    url: seoSrc,
                    className,
                    alt,
                }}
                errorFunction={logImageErrorWarning}
                testId="ldp-main-image"
            />
        </div>
    );
};

export default LDPImage;
