/* eslint-disable max-lines */
import styled, { css, keyframes } from 'styled-components';
import {
    header3,
    header7,
    caption1Bold,
    body1,
    body2,
    header4,
    textEllipsisCss,
    textLinesLimitEllipsisCss,
} from '@westwing/ui-kit/typography';
import TextTruncator from '@westwing/ui-kit/TextTruncator';
import { forTablet, forDesktop, forMidWideScreens } from '@westwing/ui-kit/breakPoints';
import { AspectRatio, Ratios } from '@westwing/ui-kit/AspectRatio';
import { WishlistBadge } from '../WishlistBadge';
import LDPImage from './LDPImage';
import { scroll } from '@westwing/ui-kit/utils';
import { midWideScreenPadding, pagePadding } from 'Client/components/Generic/BoxHelpers';

export const PageWrapper = styled.div`
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    ${pagePadding}
    ${midWideScreenPadding}
`;

export const MainBannerDiv = styled.div<{ isPortrait: boolean }>`
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 0 0 20px 20px;
    position: relative;

    ${({ isPortrait }) =>
        css`
            ${forTablet`
                display: grid;
                grid-template-columns: ${`${isPortrait ? '1fr 1fr' : '1fr'}`};
                margin: ${`${isPortrait ? '0 30px' : '0 30px 16px'}`};
            `}
        `}

    ${({ isPortrait }) =>
        !isPortrait &&
        css`
            ${forMidWideScreens`
            margin: 0 0 14px 30px;
        `}
        `}
`;

export type BroadBorderProps = {
    borderColor: string;
    isPortrait: boolean;
};
export const BroadBorder = styled.div<BroadBorderProps>`
    background: ${({ borderColor }) => borderColor};
    bottom: -20px;
    height: calc(100% - 20px);
    position: absolute;
    left: -20px;
    width: calc(100% + 20px);
    z-index: -1;
    ${({ isPortrait }) =>
        css`
            ${forTablet`
                bottom: -${isPortrait ? '30px' : '36px'};
                height: calc(100% - ${isPortrait ? '30px' : '36px'});
                left: -${isPortrait ? '30px' : '36px'};
                width: calc(${isPortrait ? '100% + 30px' : '50% + 36px'} );
            `}
        `}

    ${({ isPortrait }) => css`
        ${forMidWideScreens`
            bottom: -${isPortrait ? '30px' : '30px'};
            height: calc(100% - ${isPortrait ? '30px' : '30px'});
            left: -${isPortrait ? '30px' : '30px'};
            width: calc(${isPortrait ? '100% + 30px' : '50% + 14px'} );
        `}
    `}
`;

export const MainBannerImageSection = styled.div<{ isPortrait: boolean }>`
    background: ${({ theme }) => theme.colors.charcoal3};
    margin-bottom: 20px; /* to accomodate the broadborder -20px/-30px/-36px */
    ${({ isPortrait }) =>
        css`
            ${forTablet`
                height: fit-content;
                margin-bottom: ${isPortrait ? '30px' : '36px'};
            `}
        `}
    position: relative;
`;

export const ImageWrapper = styled.div<{ isPortrait: boolean; ratio: Ratios }>`
    position: relative;
    overflow: hidden;
    ${AspectRatio}
    ${({ isPortrait }) =>
        !isPortrait
            ? css`
                  ${scroll('x', true)}

                  ${forTablet`
                     overflow-x: hidden;
                  `}
              `
            : ''}
`;

export const ImageScroller = styled.div<{ isPortrait: boolean; ratio: Ratios }>`
    ${({ isPortrait }) =>
        css`
            width: ${isPortrait ? '100%' : '200%'};
            aspect-ratio: ${isPortrait ? '3 / 4' : '3 / 2'};
            ${AspectRatio}
            ${forTablet`
                width: 100%;
            `}
        `}
`;

export const MainBannerImage = styled(LDPImage)<{ ratio: Ratios }>`
    /* This is necessary to avoid space because of decenders -
     * https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
     */
    display: block;
    height: 100%;
    width: 100%;

    ${({ isPortrait }) => css`
        aspect-ratio: ${isPortrait ? '3 / 4' : '3 / 2'};
        ${AspectRatio}
    `}

    object-fit: cover;
`;

export const LooksTextContainer = styled.div<{ isPortrait: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    max-width: 100%;

    ${({ isPortrait }) =>
        css`
            ${forTablet`
                width: ${`${isPortrait ? '335px' : 'auto'}`};
                margin: 0 auto;
                & div, button {
                    text-align: ${isPortrait ? 'left' : 'center'};
                    margin: ${isPortrait ? 'initial' : '0 auto'};
                }
            `}
            ${forDesktop`
                width: ${`${isPortrait ? '335px' : '900px'}`};
                height: ${isPortrait ? '335px' : 'auto'};
                margin-bottom: 36px;
            `}
            align-self: center;
        `}
`;

export const StyledTitle = styled.h1<{ isPortrait: boolean; hasLongWord: boolean }>`
    ${header7}
    flex-shrink: 0;
    text-align: left;
    margin: 13px 0 28px;
    max-width: 100%;
    align-self: flex-start;

    ${({ isPortrait }) =>
        css`
            ${forTablet`
                ${header4}
                margin: ${`${isPortrait ? '13px' : '27px'}`} 0 22px ${`${isPortrait ? '10px' : '0'}`};
                text-align: ${`${isPortrait ? 'left' : 'center'}`};
                align-self: ${`${isPortrait ? 'flex-start' : 'center'}`};
            `}
            ${forDesktop`
                ${header3}
                margin-left: initial;
            `}
        `}

    ${({ hasLongWord }) =>
        hasLongWord
            ? css`
                  ${textEllipsisCss}
              `
            : css`
                  ${textLinesLimitEllipsisCss(3)}
              `}

    @supports (hyphens: auto) {
        hyphens: auto;
        white-space: initial;
        ${textLinesLimitEllipsisCss(3)}

        ${forTablet`
            hyphens: initial;
        `}
    }
`;

export const StyledDescription = styled(TextTruncator)<{
    isPortrait: boolean;
}>`
    width: 255px;
    min-height: 138px;
    ${body2}
    ${forDesktop`
        min-height: 0;
        ${body1}
    `}

    ${({ isPortrait }) =>
        !isPortrait &&
        css`
            ${forTablet`
                width: 100%;
                max-width: 900px;
            `}
        `}
`;

export const CartButton = styled.button<{ isHidden: boolean }>`
    align-items: center;
    background: white;
    color: ${({ theme }) => theme.colors.charcoal100};
    border-radius: 50%;
    bottom: 20px;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 20px;
    position: absolute;
    width: 40px;
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    :hover {
        cursor: pointer;
    }
`;

export const StyledWishlistBadge = styled(WishlistBadge)`
    position: absolute;
    top: 20px;
    right: 20px;

    ${forDesktop`${css`
        top: 28px;
        right: 28px;
    `}`}
`;

const popInAnimation = keyframes`
    0% {
        transform: scale(0);
        opacity: 0.0;
    }
    80% {
        transform: scale(1.25);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

const popOutAnimation = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        transform: scale(0);
        opacity: 0.0;
    }
`;

export const LookDetailHotSpots = styled.div<{ isVisible: boolean; isPortrait: boolean; left: number; top: number }>`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    height: 20px;
    position: absolute;
    width: 20px;
    z-index: 1;

    ${({ isPortrait, left, top }) =>
        css`
            ${`top: calc(${top}% - 10px);`}
            /* double the percent because for landscape mobile we go 200% width */
            left: calc(${isPortrait ? left : left * 2}% - 10px);
            ${forTablet`
                left: calc(${left}% - 10px);
            `}
        `}

    /* enlarge clickable area */
    :before {
        content: '';
        position: absolute;
        border-radius: 50%;
        left: -14px;
        top: -14px;
        width: 48px;
        height: 48px;

        ${forDesktop`
            left: -10px;
            top: -10px;
            width: 40px;
            height: 40px;
        `}
    }

    visibility: hidden;
    transition: visibility 0.5s linear;

    :hover {
        cursor: pointer;
    }

    ${({ isVisible }) =>
        isVisible
            ? css`
                  visibility: visible;
                  animation: 0.5s ${popInAnimation} normal;
              `
            : css`
                  visibility: hidden;
                  animation: 0.5s ${popOutAnimation} normal;
              `}
`;

export const HotSpotProduct = styled.div<{ top: number; left: number; width: number }>`
    width: ${({ width }) => `${width}px`};
    position: absolute;
    top: ${({ top }) => `${top}%`};
    left: ${({ left }) => `${left}%`};
    z-index: ${({ theme }) => theme.zIndexLooksOverlays};
`;

export const AlternativeProductHint = styled.div<{ height: number }>`
    ${caption1Bold}
    width: 100%;
    height: ${({ height }) => `${height}px`};
    display: flex;
    align-items: flex-end;
    padding: 0 15px;
    background: ${({ theme }) => theme.colors.charcoal3};
`;
