/* eslint-disable max-lines */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isDesktop, isTablet } from '@westwing/ui-kit/breakPoints';
import CartIcon from '@westwing/ui-kit/Icons/CartIcon';
import LooksTileUiKit from '@westwing/ui-kit/LooksTile';
import useAnimationFrame from '@westwing/ui-kit/Tools/hooks/useAnimationFrame';
import { calculateProductPositionInPercentage, Position } from './hotSpotUtils';
import {
    ImageWrapper,
    ImageScroller,
    MainBannerImage,
    LookDetailHotSpots,
    HotSpotProduct,
    AlternativeProductHint,
    CartButton,
    BroadBorder,
    StyledWishlistBadge,
    BroadBorderProps,
} from './LooksDetailPageElements';
import useBellaTranslator from 'Client/hooks/useTranslator';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import Product from 'Client/components/Product/GenericProduct';
import { ProductInterface } from 'Client/redux/products/types';
import { GenericLook } from 'Client/redux/looksCommonTypes';
import { looksPDPortraitRatios, looksDetailPageLandscapeRatios } from 'Client/constants/aspectRatios';
import { WishlistTrackingParameter } from 'AppShell/appshell_types';

const HOTSPOT_PRODUCT_WIDTH = 175;
const HOTSPOT_PRODUCT_HEIGHT = 1.66 * HOTSPOT_PRODUCT_WIDTH;
const HOTSPOT_FREISTELLER_PRODUCT_HEIGHT = 1.8 * HOTSPOT_PRODUCT_WIDTH;
const HOTSPOT_ALTERNATIVE_PRODUCT_HINT_HEIGHT = 24;

const SCROLL_IMAGE_TIMEOUT = 500;

const SHOW_HOTSPOTS_TIMEOUT = 500;
const HIDE_HOTSPOTS_TIMEOUT = 3000;

interface Props {
    isFetching: boolean;
    preventAutoScroll?: boolean;
    onHotSpotClick?: (productSku: string) => void;
    onWishlistBadgeClick?: (newIsSelectedValue: boolean) => void;
    onImageClick?: () => void;
    onProductOverlayClick?: (productSku: string) => void;
    onCartButtonClick?: () => void;
    trackWishlistBadgeHotspotClick?: (args: WishlistTrackingParameter) => void;
    look: GenericLook;
    backdropComponent?: React.FC<BroadBorderProps>;
    'data-testid'?: string;
}

interface ActiveHotSpot {
    ref: HTMLElement;
    product: ProductInterface;
}

// eslint-disable-next-line complexity
const LookInteractiveImage = ({
    look,
    isFetching,
    backdropComponent: BackdropComponent = BroadBorder,
    preventAutoScroll = false,
    onHotSpotClick,
    onWishlistBadgeClick,
    onImageClick,
    onProductOverlayClick,
    onCartButtonClick,
    trackWishlistBadgeHotspotClick,
    'data-testid': testId = 'interactive-image-wrapper',
}: Props) => {
    const t = useBellaTranslator();
    const { p100FreistellerImages } = useFeatureToggle();

    const [hotSpots, setHotSpots] = useState<Array<{ top: number; left: number; product: ProductInterface }>>([]);
    const showHotSpotsTimeout = useRef<null | number>(null);
    const hideHotSpotsTimeout = useRef<null | number>(null);
    const [showHotSpots, setShowHotSpots] = useState<boolean>(false);
    const [isAutoScrollReady, setIsAutoScrollReady] = useState<boolean>(false);
    const [verticalScrollPosition, setVerticalScrollPosition] = useState<number>(0);
    const lookImageWrapperRef = useRef<HTMLDivElement>(null);
    const isPortrait = !look.isLandscape;

    const [activeHotSpot, setActiveHotSpot] = useState<ActiveHotSpot | null>(null);
    const [activeHotSpotProductPosition, setActiveHotSpotProductPosition] = useState<Position | null>(null);

    const lookImageRef = useRef<HTMLDivElement>(null);

    const { id } = look;
    useEffect(() => {
        if (lookImageWrapperRef?.current?.scrollLeft !== 0) {
            lookImageWrapperRef!.current!.scrollLeft = 0;
        }
    }, [id]);

    const positionHotSpotProduct = useCallback(
        (hotSpot: ActiveHotSpot | null) => {
            if (!lookImageRef?.current || !lookImageWrapperRef?.current || !hotSpot?.ref) {
                return;
            }

            const productHeight = p100FreistellerImages
                ? HOTSPOT_FREISTELLER_PRODUCT_HEIGHT
                : HOTSPOT_PRODUCT_HEIGHT +
                  (hotSpot.product.hotspot?.isAlternative ? HOTSPOT_ALTERNATIVE_PRODUCT_HINT_HEIGHT : 0);

            const position = calculateProductPositionInPercentage({
                fullLookImage: lookImageRef.current,
                scrollableLookImage: lookImageWrapperRef.current,
                hotSpot: hotSpot.ref,
                productWidth: HOTSPOT_PRODUCT_WIDTH,
                productHeight,
                isPortrait,
                isDesktop: isDesktop(),
                isTablet: isTablet(),
            });

            setActiveHotSpotProductPosition(position);
        },
        [lookImageRef, isPortrait]
    );

    const repositionHotSpotProduct = useCallback(() => {
        positionHotSpotProduct(activeHotSpot);
    }, [activeHotSpot, positionHotSpotProduct]);

    const clearTimeoutAndIntervals = () => {
        setIsAutoScrollReady(false);

        if (showHotSpotsTimeout.current) {
            clearTimeout(showHotSpotsTimeout.current);
            showHotSpotsTimeout.current = null;
        }
        if (hideHotSpotsTimeout.current) {
            clearTimeout(hideHotSpotsTimeout.current);
            hideHotSpotsTimeout.current = null;
        }
    };

    const handleHotSpotActivation = (event: React.MouseEvent | React.FocusEvent, product: ProductInterface) => {
        const newActiveHotSpot = { ref: event.target as HTMLElement, product };
        setActiveHotSpot(newActiveHotSpot);
        positionHotSpotProduct(newActiveHotSpot);
        clearTimeoutAndIntervals();
    };

    const handleHotSpotClick = (event: React.MouseEvent, product: ProductInterface) => {
        event.stopPropagation();
        if (activeHotSpot?.product.name === product.name) {
            setActiveHotSpot(null);
        } else {
            handleHotSpotActivation(event, product);
        }
        if (onHotSpotClick) {
            onHotSpotClick(product.sku);
        }
    };

    const handleHotSpotMouseOver = (event: React.MouseEvent | React.FocusEvent, product: ProductInterface) => {
        if (isDesktop()) {
            handleHotSpotActivation(event, product);
        }
    };

    const handleWishlistBadgeClick = (newIsSelectedValue: boolean) => {
        if (onWishlistBadgeClick) {
            onWishlistBadgeClick(newIsSelectedValue);
        }
        if (activeHotSpot) {
            setActiveHotSpot(null);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', repositionHotSpotProduct, false);
        return () => {
            window.removeEventListener('resize', repositionHotSpotProduct, false);
        };
    }, [activeHotSpot, repositionHotSpotProduct]);

    const setupHotspots = (isVisible: boolean) =>
        hotSpots.map(({ left, top, product }) => (
            <LookDetailHotSpots
                onClick={event => handleHotSpotClick(event, product)}
                onMouseOver={event => handleHotSpotMouseOver(event, product)}
                onFocus={event => handleHotSpotMouseOver(event, product)}
                data-testid="look-hotspots"
                key={`${top}-${left}`}
                top={top}
                left={left}
                isPortrait={isPortrait}
                isVisible={isVisible}
            />
        ));

    const handleImageClick = () => {
        clearTimeoutAndIntervals();

        if (activeHotSpot) {
            setActiveHotSpot(null);
        } else {
            setShowHotSpots(!showHotSpots);
        }

        if (onImageClick) {
            onImageClick();
        }
    };

    const handleProductOverlayClick = (event: React.MouseEvent, product: ProductInterface) => {
        event.stopPropagation();
        if (onProductOverlayClick) {
            onProductOverlayClick(product.sku);
        }
    };

    const flashHotSpots = () => {
        showHotSpotsTimeout.current = window.setTimeout(() => {
            setShowHotSpots(true);
            hideHotSpotsTimeout.current = window.setTimeout(() => {
                setShowHotSpots(false);
            }, HIDE_HOTSPOTS_TIMEOUT);
        }, SHOW_HOTSPOTS_TIMEOUT);
    };

    useAnimationFrame(() => {
        const imageWrapper = lookImageWrapperRef.current;
        if (imageWrapper && isAutoScrollReady && !preventAutoScroll) {
            const shouldScrollImage = imageWrapper.scrollWidth !== imageWrapper.clientWidth;
            // Half the width as it is 200% for landscape.
            const maxScrollLeft = imageWrapper.scrollWidth / 2;
            if (shouldScrollImage && imageWrapper.scrollLeft < maxScrollLeft) {
                imageWrapper.scrollTo(imageWrapper.scrollLeft + 1, 0);
            } else {
                flashHotSpots();
                setIsAutoScrollReady(false);
            }
        }
    }, [isAutoScrollReady && !preventAutoScroll]);

    const handleImageTouchMove = (ev: React.TouchEvent) => {
        // when touch move event is tracked we can expect that user is scrolling something on page
        // and if view port vertical position is not changed then image is scrolling horizontally and we should stop automatic scrolling
        if (ev.changedTouches[0] && verticalScrollPosition === ev.changedTouches[0].screenY) {
            clearTimeoutAndIntervals();
        }
    };

    const handleTouchStart = (ev: React.TouchEvent) => {
        // save vertical scroll position, to use it in case user starts to scroll screen/image
        if (ev.changedTouches[0]) {
            setVerticalScrollPosition(ev.changedTouches[0].screenY);
        }
    };

    const handleImageIsFullyLoaded = () => {
        if (isDesktop()) {
            flashHotSpots();
        } else {
            setTimeout(() => setIsAutoScrollReady(true), SCROLL_IMAGE_TIMEOUT);
        }
    };

    const handleCartButtonClick = () => {
        clearTimeoutAndIntervals();

        if (activeHotSpot) {
            setActiveHotSpot(null);
        }
        setShowHotSpots(!showHotSpots);

        if (onCartButtonClick) {
            onCartButtonClick();
        }
    };

    useEffect(
        () => () => {
            setActiveHotSpot(null);
            clearTimeoutAndIntervals();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [look.slug]
    );

    useEffect(() => {
        const products = look.products
            .filter(p => p.hotspot)
            .map(p => ({ top: p.hotspot?.y || 0, left: p.hotspot?.x || 0, product: p }));
        setHotSpots(products);
    }, [look]);

    const isCartButtonDisabled = !lookImageRef?.current;
    const isCartButtonHidden = !!lookImageRef?.current && !isFetching && !look.products.filter(p => p.hotspot).length;
    const currentRatio = isPortrait ? looksPDPortraitRatios : looksDetailPageLandscapeRatios;
    return (
        <>
            <LooksTileUiKit.TopRightBadge>
                <StyledWishlistBadge
                    lookName={look.name}
                    lookSlug={look.slug}
                    testId="interactive-image-wishlist-badge"
                    onClick={handleWishlistBadgeClick}
                />
            </LooksTileUiKit.TopRightBadge>
            <BackdropComponent borderColor={look.color} isPortrait={isPortrait} />
            <ImageWrapper
                isPortrait={isPortrait}
                ref={lookImageWrapperRef}
                onClick={handleImageClick}
                onPointerUp={clearTimeoutAndIntervals}
                onTouchMove={handleImageTouchMove}
                onTouchStart={handleTouchStart}
                ratio={currentRatio}
                data-testid={testId}
            >
                <ImageScroller
                    isPortrait={isPortrait}
                    ref={lookImageRef}
                    data-testid="image-scroller"
                    ratio={currentRatio}
                >
                    <MainBannerImage
                        alt={look.name}
                        isPortrait={isPortrait}
                        src={look.moodImage.url}
                        seoSrc={look.moodImage.seoUrl}
                        onImageLoad={handleImageIsFullyLoaded}
                        loading="eager"
                        ratio={currentRatio}
                    />
                    {setupHotspots(showHotSpots)}
                    {activeHotSpot && activeHotSpotProductPosition && (
                        <HotSpotProduct
                            top={activeHotSpotProductPosition.top}
                            left={activeHotSpotProductPosition.left}
                            width={HOTSPOT_PRODUCT_WIDTH}
                            onClick={(e: React.MouseEvent) => handleProductOverlayClick(e, activeHotSpot.product)}
                            data-testid="hotspot-product-overlay"
                        >
                            {activeHotSpot.product.hotspot?.isAlternative && (
                                <AlternativeProductHint height={HOTSPOT_ALTERNATIVE_PRODUCT_HINT_HEIGHT}>
                                    {t('Similar product as in the look')}
                                </AlternativeProductHint>
                            )}
                            <Product
                                product={{
                                    ...activeHotSpot.product,
                                    wishListTrackingFn:
                                        activeHotSpot.product.wishListTrackingFn ?? trackWishlistBadgeHotspotClick,
                                }}
                                showProductHoverEffect={false}
                                isLooksHotspot
                            />
                        </HotSpotProduct>
                    )}
                </ImageScroller>
            </ImageWrapper>
            <CartButton
                disabled={isCartButtonDisabled}
                isHidden={isCartButtonHidden}
                onClick={handleCartButtonClick}
                data-testid="looks-cart-button"
            >
                <CartIcon />
            </CartButton>
        </>
    );
};

export default LookInteractiveImage;
