export interface Position {
    top: number;
    left: number;
}

export const calculateProductPositionInPercentage = ({
    fullLookImage,
    scrollableLookImage,
    hotSpot,
    productWidth,
    productHeight,
    isPortrait,
    isDesktop,
    isTablet,
}: {
    fullLookImage: HTMLElement;
    scrollableLookImage: HTMLDivElement;
    hotSpot: HTMLElement;
    productWidth: number;
    productHeight: number;
    isPortrait: boolean;
    isDesktop: boolean;
    isTablet: boolean;
}): Position => {
    const productHotSpotPadding = 10;
    const productEdgesPadding = isDesktop ? 20 : 10;

    const hotSpotSize = hotSpot.clientWidth;
    const hotSpotOffsetTop = hotSpot.offsetTop;
    const hotSpotOffsetLeft = hotSpot.offsetLeft;
    const fullLookImageHeight = fullLookImage.clientHeight;
    const fullLookImageWidth = fullLookImage.clientWidth;
    const scrollableLookImageWidth = scrollableLookImage.clientWidth;
    const scrollOffsetLeft = scrollableLookImage.scrollLeft;

    const offsetTopPercentage = (hotSpotOffsetTop / fullLookImageHeight) * 100;

    let productOffsetTop =
        offsetTopPercentage > 50
            ? hotSpotOffsetTop - productHeight - productHotSpotPadding
            : hotSpotOffsetTop + productHotSpotPadding + hotSpotSize;

    let productOffsetLeft = hotSpotOffsetLeft - productWidth / 2 + hotSpotSize / 2;

    const leftmostVisiblePoint = scrollOffsetLeft;
    const productCurrentLeftmostPoint = productOffsetLeft - productEdgesPadding;
    if (productCurrentLeftmostPoint < leftmostVisiblePoint) {
        productOffsetLeft += leftmostVisiblePoint - productCurrentLeftmostPoint;
    }

    const rightmostVisiblePoint = scrollOffsetLeft + scrollableLookImageWidth;
    const productCurrentRightmostPoint = productOffsetLeft + productWidth + productEdgesPadding;
    if (productCurrentRightmostPoint > rightmostVisiblePoint) {
        productOffsetLeft -= productCurrentRightmostPoint - rightmostVisiblePoint;
    }

    const bottommostVisiblePoint = fullLookImageHeight;
    const productCurrentBottommostPoint = productOffsetTop + productHeight + productEdgesPadding;
    if (productCurrentBottommostPoint > bottommostVisiblePoint) {
        productOffsetTop -= productCurrentBottommostPoint - bottommostVisiblePoint;
    }

    if (productOffsetTop < productEdgesPadding) {
        productOffsetTop = productEdgesPadding;
    }

    const lookImageWidthInPercentage = isPortrait || isTablet ? 100 : 200;
    const productOffsetTopPercentage = (productOffsetTop / fullLookImageHeight) * 100;
    const productOffsetLeftPercentage = (productOffsetLeft / fullLookImageWidth) * lookImageWidthInPercentage;

    return {
        top: productOffsetTopPercentage,
        left: productOffsetLeftPercentage,
    };
};
